import {startCase} from "lodash";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "neo-input",
    components: {
        "font-awesome-icon": FontAwesomeIcon,
    },
    props: {
        type: {
            type: String,
            default: "text",
        },
        required: {type: Boolean, default: false},
        mappedKey: null,
        placeholder: {},
        prependIcon: "",
        label: {},
        value: {},
        icon: {},
        iconFocus: {},
        margin: {},
        bg: {},
        border: {
            default: "8px",
        },
        borderFull: {},
        borderColor: {},
        changeCase: {},
        shadow: {
            default: true,
        },
        textLimit: {
            type: Number,
            default: -1,
        },
        disabled: Boolean,
        inputPadding: {
            type: String,
            default: "0 0.8rem",
        },
        inputHeight: {
            type: String,
            default: "2.5rem",
        },
        inputId: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            ico: "",
            focus: false,
            startCase: startCase,
        };
    },
    computed: {},
    created() {
        this.ico = this.icon;
    },
    methods: {
        onInput(newValue, mappedKey, placeholder) {
            if (this.textLimit !== -1) {
                let val = newValue.slice(0, this.textLimit);
                this.$emit("input", val, mappedKey, placeholder);
            } else {
                this.$emit("input", newValue, mappedKey, placeholder);
            }
        },
    },
};
