import Button from "@/components/button";
import axios from "@/axios";

export default {
    name: "neo-comments",
    props: {
        uploadUrl: {
            type: String,
            default: () => "",
        },
    },
    components: {
        "neo-button": Button
    },
    data() {
        return {
            file: "",
            status: "",
            case_id: "",
            tool_name: "",
            filename: "",
            table_data: [],
        };
    },
    computed: {},
    mounted() {
        this.case_id = this.$route.params.id;
        this.tool_name = this.$route.params.toolName;
    },
    methods: {
        cancelUpload() {
            (this.file = []), (this.$refs.file.value = ""), (this.status = "");
        },
        async uploadFile(valid = false) {
            this.status = "uploading";
            this.file = this.$refs.file.files;
            this.file = Object.keys(this.file).map((fl) => this.file[fl]);
            // if(this.file.type!='text/csv'){
            //     this.status=''
            //     this.$refs.file.value=''
            //     this.$toast.error('Invalid file type');
            //     return
            // }
            let fileExt = this.file.map((fl) => fl.name);
            let validExts = new Array(".xlsx", ".xls");
            let header = {
                "Content-Type": "multipart/form-data",
            };
            this.filename = fileExt.join(" + ");
            fileExt = fileExt.map((fle) => fle.substring(fle.lastIndexOf(".")));
            if (!fileExt.every((fle) => validExts.includes(fle))) {
                this.$toast.error("Invalid file selected, valid files are of " + validExts.toString() + " types.");
                this.cancelUpload();
                return false;
            } else {
                let formData = new FormData();
                let excelFiles = this.file;
                // send multiple files as array
                formData.append("case_id", this.case_id);
                formData.append("tool_name", this.tool_name);
                excelFiles.forEach((efl) => {
                    formData.append("excel_files", efl);
                });
                if (valid) {
                    try {
                        let url = `/osint-tools/search/bulk-upload-submit`;
                        const response = await axios.post(url, formData, {
                            headers: header,
                        });
                        if (response?.status === 200 && response?.data?.message) {
                            if (response?.data?.status) {
                                this.$toast.success(response.data.message);
                            } else {
                                this.cancelUpload();
                                this.$toast.error(response.data.message);
                            }
                        }
                    } catch (error) {
                        this.$toast.error(error.message || "something went wrong \n please try again");
                    }
                    this.cancelUpload();
                    this.$modal.hide("upload_xl_valid");
                }
                /// check for validation
                else {
                    try {
                        let url = `/osint-tools/search/bulk-upload-check`;
                        const response = await axios.post(url, formData, {
                            headers: header,
                        });
                        if (response?.status === 200 && response?.data?.status) {
                            this.$modal.show("upload_xl_valid");
                            this.table_data = response.data.data;
                        } else {
                            this.$toast.error(response.data.message);
                            this.cancelUpload();
                        }
                    } catch (error) {
                        this.cancelUpload();
                    }
                }
            }
        },
    },
};
