<template>
    <div class="print-d-block flex flex-col space-y-4 overflow-y-auto px-2 overflow-x-hidden">
        <!-- {{expandedCard.sources}} -->
        <div @click="handleExpandAll" class="print-hidden text-blue-600 ml-auto cursor-pointer hover:text-blue-400 inline-flex">
            <template v-if="!adverseCard.expandedPanel">Expand All</template>
            <template v-else>Collapse All</template>
        </div>

        <div class="mb-2">
            <template v-if="profileData">
                <div ref="refAliases" @click="fetchMoreInfo(adverseCard, 'aliases')" class="headingBlock print-d-block flex items-center py-1 px-2 bg-blue-50 mt-4 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                    <div class="text-lg font-medium">Profile Data</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{'rotate-180': adverseCard.aliasesExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="p-2 headingBlock-sibling" v-if="adverseCard.aliasesExpanded">
                    <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Names</div>
                    <ul class="mb-4 pl-3 space-y-2">
                        <li v-for="(alias, index) in get(adverseCard, 'data.EntityDetails.AKAs', [])" :key="alias" :identifier="`['data']['AKAs'][${index}]`" class="list-none">{{ alias.Name.Full }} ({{ getCategory(alias.Category) }})</li>
                    </ul>
                    <div class="mt-2">
                        <div class="mb-2 flex space-x-2" identifier="['data']['position'][0]">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Position</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center" v-for="(data, index) in get(adverseCard, 'data.EntityDetails.AdditionalInfo', [])" :key="index">
                                    <span class="space-y-2" v-if="data.Type === 'Occupation'">
                                        <template>{{ data.Value }}</template>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 flex space-x-2" identifier="['data']['place_of_birth']">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Place of Birth</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center" v-for="(data, index) in get(adverseCard, 'data.EntityDetails.AdditionalInfo', [])" :key="index">
                                    <span class="space-y-2" v-if="data.Type === 'PlaceOfBirth'">
                                        <template>{{ data.Value }}</template>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 flex space-x-2" identifier="['data']['dob']">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">DOB</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center" v-for="(data, index) in get(adverseCard, 'data.EntityDetails.AdditionalInfo', [])" :key="index">
                                    <span class="space-y-2" v-if="data.Type === 'DOB'">
                                        <template v-if="data.Value">{{ data.Value }}</template>
                                        <template v-else>-</template>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 flex space-x-2">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">ProprietaryUID</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center flex-wrap" v-for="(data, index) in get(adverseCard, 'data.EntityDetails.IDs', [])" :key="index">
                                    <template v-if="data.Type === 'ProprietaryUID'">
                                        <span class="space-y-2" :identifier="`['data']['ProprietaryUID'][${index}]`" v-if="data.Number">
                                            {{ data.Number }}
                                        </span>
                                        <span class="space-y-2" v-else>-</span>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 flex space-x-2">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Address</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center flex-wrap" v-for="(addDet, addIndex) in getAddress(adverseCard.data.EntityDetails.Addresses)" :key="addIndex">
                                    <span class="space-y-2 mb-2" :identifier="`['data']['Address'][${addIndex}]['Street1']`">
                                        {{
                                            get(addDet, "Street1", "")
                                                .split(";")
                                                .join(" ")
                                        }}
                                        {{
                                            get(addDet, "City", "")
                                                .split(";")
                                                .join(" ")
                                        }}
                                        {{
                                            get(addDet, "PostalCode", "")
                                                .split(";")
                                                .join(" ")
                                        }}
                                        {{
                                            get(addDet, "StateProvinceDistrict", "")
                                                .split(";")
                                                .join(" ")
                                        }}
                                        {{
                                            get(addDet, "Country", "")
                                                .split(";")
                                                .join(" ")
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded">
                    <div class="text-lg font-medium">Profile Data</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.aliasesExpanded"></div>
            </template>
        </div>
        <div class="mb-2">
            <template v-if="screeningListData">
                <div ref="refAliases" @click="fetchMoreInfo(adverseCard, 'screening')" class="headingBlock print-d-block flex items-center py-1 px-2 bg-blue-50 mt-4 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                    <div class="text-lg font-medium">Screening List</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{'rotate-180': adverseCard.screeningExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="p-2 headingBlock-sibling" v-if="adverseCard.screeningExpanded">
                    <div class="mt-2">
                        <div class="mb-2 flex space-x-2" :identifier="`['adverseCard']['data']['File']['Name']`">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Screening List Name</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center">
                                    <span class="space-y-2 mb-2">
                                        <template> {{ get(adverseCard.data, "File").Name }}</template>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 flex space-x-2" :identifier="`['adverseCard']['data']['File']['Build']`">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">File Build:</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center">
                                    <span class="space-y-2 mb-2">
                                        {{ getFormattedTime(get(adverseCard.data, "File").Build) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 flex space-x-2" :identifier="`['adverseCard']['data']['EntityDetails']['DateListed']`">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Entity Created:</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center">
                                    <span class="space-y-2 mb-2">
                                        {{ getFormattedTime(adverseCard.data.EntityDetails.DateListed) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 flex space-x-2" :identifier="`['adverseCard']['data']['EntityDetails']['DateModified']`">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Entity Last Updated:</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center">
                                    <span class="space-y-2 mb-2">
                                        {{ getFormattedTime(adverseCard.data.DateModified) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 flex space-x-2" :identifier="`['adverseCard']['data']['ReasonListed']`">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Reason Listed:</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center">
                                    <span class="space-y-2 mb-2">
                                        {{ adverseCard.data.ReasonListed }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded">
                    <div class="text-lg font-medium">Screening List</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.screeningExpanded"></div>
            </template>
        </div>
        <div class="mb-2">
            <template v-if="get(adverseCard.data.EntityDetails, 'Comments', '').length > 0">
                <div ref="refAliases" @click="fetchMoreInfo(adverseCard, 'comments')" class="headingBlock print-d-block flex items-center py-1 px-2 bg-blue-50 mt-4 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                    <div class="text-lg font-medium">Comments</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{'rotate-180': adverseCard.commentsExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="p-2 headingBlock-sibling" v-if="adverseCard.commentsExpanded">
                    <div class="mt-2">
                        <div class="mb-2 flex space-x-2" :identifier="`['adverseCard']['data']['EntityDetails']['Comments']`">
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center" v-for="(data, index) in get(adverseCard.data.EntityDetails, 'Comments', '').split('|')" :key="index">
                                    <span class="space-y-2 mb-2">
                                        <span v-html="getProfileNotes(data)"></span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded">
                    <div class="text-lg font-medium">Comments</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.commentsExpanded"></div>
            </template>
        </div>

        <!-- <div class="mb-2">
            <template v-if="get(adverseCard, 'data.EntityDetails.Addresses', []).length > 0">
                <div ref="refAddressSection" @click="fetchMoreInfo(adverseCard, 'addressesLexisBridger')" class="headingBlock flex items-center py-1 px-2 bg-blue-50 mt-4 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                    <div class="text-lg font-medium">Addresses</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{'rotate-180': adverseCard.addressesLexisBridgerExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="p-2 headingBlock-sibling" v-if="adverseCard.addressesLexisBridgerExpanded">
                    <template v-if="adverseCard.data.EntityDetails.EntityType == 'Individual' || adverseCard.data.EntityDetails.EntityType == 'Business'">
                        <div class="print-overflow-table mb-2 flex flex-col addressesDowjones">
                            <table class="min-w-full divide-y divide-gray-200 border border-solid border-gray-300 mb-2">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Line
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            City
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            State
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Country
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr v-for="(addDet, addIndex) in getAddress(adverseCard.data.EntityDetails.Addresses)" :key="addIndex">
                                        <td class="px-6 py-3 text-left" :identifier="`['data']['Address'][${addIndex}]['Street1']`">
                                            {{
                                                get(addDet, "Street1", "")
                                                    .split(";")
                                                    .join(" ")
                                            }}
                                        </td>
                                        <td class="px-6 py-3 text-left" :identifier="`['data']['Address'][${addIndex}]['City']`">
                                            {{
                                                get(addDet, "City", "")
                                                    .split(";")
                                                    .join(" ")
                                            }}
                                        </td>
                                        <td class="px-6 py-3 text-left" :identifier="`['data']['Address'][${addIndex}]['StateProvinceDistrict']`">
                                            {{
                                                get(addDet, "StateProvinceDistrict", "")
                                                    .split(";")
                                                    .join(" ")
                                            }}
                                        </td>
                                        <td class="px-6 py-3 text-left" :identifier="`['data']['Address'][${addIndex}]['Country']`">
                                            {{
                                                get(addDet, "Country", "")
                                                    .split(";")
                                                    .join(" ")
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                </div>
            </template>
            <template v-else>
                <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded">
                    <div class="text-lg font-medium">Addresses</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.addressesLexisBridgerExpanded"></div>
            </template>
        </div> -->
        <div class="mb-2">
            <template v-if="get(adverseCard, 'data.EntityDetails.AdditionalInfo', []).length > 0">
                <div ref="refAliases" @click="fetchMoreInfo(adverseCard, 'otherinfo')" class="headingBlock print-d-block flex items-center py-1 px-2 bg-blue-50 mt-4 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                    <div class="text-lg font-medium">Additional Information</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{'rotate-180': adverseCard.otherinfoExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="p-2 headingBlock-sibling" v-if="adverseCard.otherinfoExpanded">
                    <div class="mt-2">
                        <div class="mb-2 flex space-x-2" :identifier="`['adverseCard']['data']['EntityDetails']['AdditionalInfo']`">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Other Information:</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center" v-for="(data, index) in get(adverseCard, 'data.EntityDetails.AdditionalInfo', [])" :key="index">
                                    <div class="space-y-2" v-if="data.Type === 'Other' && data.Value !== 'Sources of Record Information'">
                                        <span class="space-y-2 mb-2">
                                            <template>{{ data.Value }}</template>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div class="mb-2 flex space-x-2" :identifier="`['adverseCard']['data']['EntityDetails']['AdditionalInfo']`">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Comments:</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center" v-for="(data, index) in get(adverseCard, 'data.EntityDetails.AdditionalInfo', [])" :key="index">
                                    <div class="space-y-2" v-if="data.Type === 'Other' && data.Value !== 'Sources of Record Information'">
                                        <span class="space-y-2 mb-2" v-if="data.Comments">
                                            <a :href="data.Comments.split('|')[1]" class="text-blue-600 hover:text-blue-900 no-underline" target="_blank">
                                                <span class="break-words"> {{ data.Comments.split("|")[1] }} </span>
                                            </a>
                                        </span>
                                        <span class="space-y-2 mb-2" v-else>-</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded">
                    <div class="text-lg font-medium">Additional Information</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.otherinfoExpanded"></div>
            </template>
        </div>
        <div class="mb-2">
            <template v-if="get(adverseCard, 'data.EntityDetails.AdditionalInfo', []).length > 0">
                <div ref="refAliases" @click="fetchMoreInfo(adverseCard, 'sources')" class="headingBlock print-d-block flex items-center py-1 px-2 bg-blue-50 mt-4 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                    <div class="text-lg font-medium">Record Sources</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{'rotate-180': adverseCard.sourcesExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="p-2 headingBlock-sibling" v-if="adverseCard.sourcesExpanded">
                    <div class="mt-2">
                        <div class="mb-2 flex space-x-2" :identifier="`['adverseCard']['data']['EntityDetails']['AdditionalInfo']`">
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center" v-for="(data, index) in get(adverseCard, 'data.EntityDetails.AdditionalInfo', [])" :key="index">
                                    <div class="space-y-2" v-if="data.Type === 'Other' && data.Value === 'Sources of Record Information' && data.Comments">
                                        <div class="flex justify-between items-center" v-for="(data1, index) in data.Comments.split('|')" :key="index">
                                            <span class="space-y-2 mb-2">
                                                <a :href="data1" class="text-blue-600 hover:text-blue-900 no-underline" target="_blank">
                                                    <span class="break-words"> {{ data1 }} </span>
                                                </a>
                                                <!-- <template><a>{{ data1 }}</a></template> -->
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded">
                    <div class="text-lg font-medium">Record Sources</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.sourcesExpanded"></div>
            </template>
        </div>
    </div>
</template>
<script>
// import { DateTime } from "luxon";
import {get} from "lodash";
import {DateTime} from "luxon";
import adverseMixin from "@shared/tools/mixins/adverseMixin";
import printDataMixin from "@shared/tools/mixins/printDataMixin";


export default {
    components: {},
    data() {
        return {
            urlFields: ["related url", "locationurl"],
            isPrintLoader: false,
            refBtnClick: {
                advDetails: true,
                advAssociates: true,
                advPEP: true,
                advPepClass: true,
                advFitness: true,
                advImages: true,
                advAdverseMedia: true,
                advMediaData: true,
                refNameSection: true,
                refcontryTerritoryDetails: true,
                refDatesSection: true,
                refAddressSection: true,
                refImagesSection: true,
                refRolesSection: true,
                refRelativeCloseDowjonesSection: true,
                refProfileNotesDowjonesSection: true,
                refSourceSection: true,
                refworldcheck: true,
                refAliases: true,
                refCitizenships: true,
                refLocations: true,
                refFurtherInfo: true,
                refInformationSources: true,
            },
            printDone: false,
        };
    },
    mixins: [adverseMixin, printDataMixin],
    props: {
        expandedCard: {
            type: Object,
        },
        isResultExpanded: {
            type: Boolean,
        },
        source: {
            type: String,
        },
        isComparisonModeOn: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isMedia() {
            return this.adverseCard?.doc.media && this.adverseCard?.doc.media.length > 0;
        },
        adverseCardKey() {
            return this.expandedCard?.key;
        },
        adverseCard() {
            return this.expandedCard?.api_all_data ?? {};
        },

        profileData() {
            return get(this.adverseCard, "data.EntityDetails.AKAs", []).length > 0 || get(this.adverseCard, "data.EntityDetails.AdditionalInfo", []).length || get(this.adverseCard, "data.EntityDetails.IDs", []).length || get(this.adverseCard, "data.EntityDetails.Addresses", []).length;
        },

        screeningListData() {
            return Object.keys(get(this.adverseCard.data, "File", "")).length > 0 || this.adverseCard.data.DateModified || this.adverseCard.data.EntityDetails.DateListed || this.adverseCard.data.ReasonListed;
        },
    },
    watch: {},
    mounted() {
    
    },
    methods: {
        get,

        getIdentifierMapping(key, index, listLength) {
            let mapping = "";
            if (key === "dateDetails")
                mapping = `['data']['${this.source}']['DateDetails']['Date'][${index}]['DateValue']['@Day'] 
                ['data']['${this.source}']['DateDetails']['Date'][${index}]['DateValue']['@Month'] 
                ['data']['${this.source}']['DateDetails']['Date'][${index}]['DateValue']['@Year']`;
            else if (key === "countryDetails") {
                for (let i = 0; i < listLength; i++) mapping += ` ['data']['${this.source}']['CountryDetails'][${index}]['CountryValue'][${i}]['@name']`;
            } else if (key === "dates") {
                if (listLength >= 0)
                    mapping = `['data']['${this.source}']['DateDetails']['Date'][${index}]['DateValue'][${listLength}]['@Day']
                    ['data']['${this.source}']['DateDetails']['Date'][${index}]['DateValue'][${listLength}]['@Month']
                    ['data']['${this.source}']['DateDetails']['Date'][${index}]['DateValue'][${listLength}]['@Year']`;
                else
                    mapping = `['data']['${this.source}']['DateDetails']['Date'][${index}]['DateValue']['@Day']
                    ['data']['${this.source}']['DateDetails']['Date'][${index}]['DateValue']['@Month']
                    ['data']['${this.source}']['DateDetails']['Date'][${index}]['DateValue']['@Year']`;
            } else if (key === "SanctionRefs") mapping = `['data']['${this.source}']['SanctionsReferences'][${index}]['data']['@name'] ['data']['${this.source}']['SanctionsReferences'][${index}]['Value']['#text']`;
            else if (key === "SanctionRefsSince") mapping = `['data']['${this.source}']['SanctionsReferences'][${index}]['@SinceDay'] ['data']['${this.source}']['SanctionsReferences'][${index}]['@SinceMonth'] ['data']['${this.source}']['SanctionsReferences'][${index}]['@SinceYear']`;
            else if (key === "SanctionRefsTo") mapping = `['data']['${this.source}']['SanctionsReferences'][${index}]['@ToDay'] ['data']['${this.source}']['SanctionsReferences'][${index}]['@ToMonth'] ['data']['${this.source}']['SanctionsReferences'][${index}]['@ToYear']`;
            return mapping;
        },

        getCategory(category) {
            if (category === "None") return "AKA";
            else return category + " AKA";
        },

        getImages(adverseCard) {
            let img_arr = [];
            let img = adverseCard.Images;
            if (img) {
                if (Array.isArray(img)) {
                    img_arr = img;
                    // return img
                } else {
                    img_arr = img.Image;
                    // return img.Image
                }
            }
            return img_arr;
        },
        get_image_url(img) {
            if (img["@URL"] !== undefined) {
                return img["@URL"];
            } else {
                return "";
            }
        },
        async printPageLexis(id, name, print, noPrint) {
            this.printDone = false;
            this.isPrintLoader = true;
            await this.handleExpandAll(true);
            if (!print) {
                let htmlPDF = await this.printData(id, name, noPrint);
                this.$emit("updatePrintStatus", "");
                this.isPrintLoader = false;
                if (noPrint) {
                    return htmlPDF;
                }
            }
        },
        getAddress(address) {
            if (address) {
                if (Array.isArray(address)) return address;
                else {
                    let obj = [address];
                    return obj;
                }
            } else return [];
        },
        getProfileNotes(data) {
            /* eslint-disable no-useless-escape */
            /// ADDING A NEWLINE CHARACTER AT STARTING OF THE NOTES TO MAKE IT EASIER TO MATCH HEADING TEXT
            /// THEN REPLACING IT WITH BOLD HTML
            /// REMOVE THE NEWLINE CHARACTER ADDED IN FIRST STEP
            /// REPLACE ALL NEWLINE CHARACTERS TO LINE-BREAK
            let notes = "\n" + data;
            notes = notes.replaceAll(/\n.+?\:/g, (str) => {
                return `\n <span class='font-bold inline-block tracking-wider'> ${str} </span>`;
            });
            /* eslint-enable no-useless-escape */
            return notes.replace("\n", "").replaceAll("\n", "<br />");
        },
        getFormattedTime(ts) {
            if (ts) {
                let date = new Date(ts).toISOString();
                date = DateTime.fromISO(date);
                return date.toFormat("dd MMM yyyy");
            }
            return "-";
        },
    },
};
</script>


<style scoped>
@media print {
    .rotate-180 {
        display: none !important;
    }
    .searchIcon {
        display: none !important;
    }
}
</style>
<style>
@media print {
    .Vue-Toastification__container {
        display: none !important;
    }
    .print-hidden {
        display: none !important;
    }
    .searchIcon {
        display: none !important;
    }
    .relatives-close-associates {
        overflow-x: hidden !important;
    }
    .print-d-block {
        display: block !important;
    }
    table {
        page-break-inside: auto !important;
    }
    table tr {
        page-break-inside: avoid !important;
        page-break-after: auto !important;
        page-break-before: auto !important;
    }
    table th {
        page-break-inside: avoid !important;
        page-break-after: auto !important;
        page-break-before: auto !important;
    }
    table tr div {
        page-break-inside: avoid !important;
        page-break-after: auto !important;
        page-break-before: auto !important;
    }
    table tr:before {
        min-height: 60px !important;
    }
    div {
        page-break-inside: auto !important;
        page-break-after: auto !important;
        page-break-before: auto !important;
        box-sizing: content-box !important;
    }
    tr {
        page-break-inside: avoid !important;
    }
    table {
        table-layout: fixed !important;
    }
    /* table, */
    thead,
    /* tbody, */
    /* tr  */
    /* td, */
    th {
        display: block !important;
    }
    table thead tr {
        top: -9999px !important;
        left: -9999px !important;
        display: none !important;
    }

    table tr:nth-child(odd) {
        background: #f6f8fc !important;
    }
    table tr {
        /* border: none !important;
        border-bottom: 1px solid #eee !important;
        position: relative !important;
        align-items: center !important;
        display: flex !important;
        white-space: normal !important;
        word-wrap: break-word !important;
        padding: 5px 15px !important; */
        width: 100%;
    }
}
</style>
