import NeoLoader from "@/components/loader";
import OsintAxios from "@shared/light-ray/axios/osint-axios";

export default {
    name: "more-person-identifier",
    components: {NeoLoader},
    props: {
        content: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            loading: false,
            personData: this.content,
        };
    },
    watch: {
        content: {
            handler: function (newValue) {
                this.personData = newValue;
            },
            deep: true,
        },
    },
    mounted() {},
    methods: {
        async fetch_more_data() {
            if (!this.personData.all_data) {
                this.loading = true;
                const {data} = await OsintAxios.post(
                    "/person-identifier/more",
                    {
                        search_pointer: this.personData["@search_pointer"],
                    },
                    {
                        headers: {
                            "x-tool-name": this.$route.params.toolName,
                            "x-visited": true,
                        },
                    }
                );
                let pData = {};
                if (data?.data?.person) {
                    pData = data.data.person;
                    pData.all_data = true;
                    pData.expanded = true;
                    this.loading = false;
                    this.personData = pData;
                } else {
                    this.loading = false;
                    this.personData.allData = true;
                }
            }
        },
    },
};
