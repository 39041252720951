import NeoNewsExpanded from "@/components/news-expanded";
import MorePersonIdentifier from "@/components/more-person-identifier";
import AdverseMediaExpand from "@/components/adverse-media-expand";

export default {
    name: "result-expanded",
    components: {NeoNewsExpanded, MorePersonIdentifier, AdverseMediaExpand},
    props: {
        content: {
            type: Object,
            default: {},
        },
        cardComponent: {
            type: String,
            required: true,
        },
        expandedLoading: {
            type: Boolean,
            default: false,
        },
        allLanguages: {
            type: Array,
            default: () => [],
        },
        isResultExpanded: {
            type: Boolean,
            default: false,
        },
        isSavedLoading: {
            type: Object,
            default: () => {},
        },
        isOldVersionSelected: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {},
};
